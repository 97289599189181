import {useEffect, useState} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import getDesignTokens from "./utils/designToken";
import Snack from "./components/Snack";
import {checkAuthState} from "./store/actions/auth";
import {connect} from "react-redux";
import PrimaryNavigation from "./navigation/primaryNavigation";
import FullPageLoader from "./components/Loaders/FullPageLoader";

const App = ({checkAuthState, isAuthenticated, isInitialAuthFinished}) => {
    const navigate = useNavigate();
    const [mode] = useState("light")
    const theme = createTheme(getDesignTokens(mode))

    useEffect(() => {
        checkAuthState();
        console.debug(theme)
    }, [checkAuthState])

    useEffect(() => {
        if (isInitialAuthFinished && !isAuthenticated) {
          console.debug('User is not authenticated, redirecting to login...');
          navigate('/auth/login');
        }
      }, [isAuthenticated, isInitialAuthFinished, navigate]);

    if (!isInitialAuthFinished) {
        return (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <FullPageLoader />
          </ThemeProvider>
        );
      }

      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PrimaryNavigation isAuthenticated={isAuthenticated} />
          <Snack />
        </ThemeProvider>
      );
};


const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        isInitialAuthFinished: state.auth.isInitialAuthFinished
    };
};


const mapDispatchToProps = dispatch => {
    return {
        checkAuthState: () => dispatch(checkAuthState()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);