import React from 'react';
import {TableCell, TableRow, Typography, useTheme} from "@mui/material";
import CustomAPIAutocompleteSelectField from "../CustomAPIAutocompleteSelectField";
import {contentService} from "../../api/services/contentService";
import CustomAutocompleteSelectField from "../CustomAutocompleteSelectField";
import CustomDateTimePicker from "../CustomDateTimePicker";
import {offerService} from "../../api/services/offerService";

const checkOptions = (configList, configToCheck) => {
    return configToCheck && configList.some(config => config.key === configToCheck.key);
}

const ManualScheduleTableRow = ({item, itemIndex, handleChangeSettings}) => {
    const theme = useTheme()
    const labelId = `enhanced-table-checkbox-${itemIndex}`;
    const configOptions = item.mailing_list.configs.map((item) => {
        return {
            key: item.name,
            value: item.id
        }
    })

    const validConfigOption = item.config && configOptions.some(option => option.value === item.config.value)
    return (
        <TableRow
            // hover
            disabled={true}
            // onClick={(event) => console.debug("click")}
            role="checkbox"
            // aria-checked={isItemSelected}
            tabIndex={-1}
            key={item.id}
            sx={{
                height: theme.customTokens.defaultRowHeight
            }}
        >

            <TableCell
                padding={"none"}
                sx={{
                    height: theme.customTokens.defaultRowHeight,
                    pl: 2
                }}>
                <Typography variant={"tableHighlight"}>

                    {item.mailing_list.name}
                </Typography>
            </TableCell>
            <TableCell padding="none" sx={{px: 2, height: theme.customTokens.defaultRowHeight}}>
                <CustomAPIAutocompleteSelectField
                    multiple={false}
                    showHelperText={false}
                    apiCall={offerService.getItems}
                    onChange={(newValue) => handleChangeSettings(item.id, "offer", newValue)}
                    // isOptionEqualToValue={(option, value) => option.key === value.key}
                    apiFilters={{
                        available: true
                    }}
                    value={item.offer}

                    ignoreInput={true}
                    disableCloseOnSelect={false}
                />
            </TableCell>
            <TableCell padding="none" sx={{px: 2, height: theme.customTokens.defaultRowHeight}}>
                <CustomAPIAutocompleteSelectField
                    multiple={false}
                    showHelperText={false}
                    apiCall={contentService.getItems}
                    onChange={(newValue) => handleChangeSettings(item.id, "content", newValue)}
                    apiFilters={{
                        offer_id: [item.offer]
                    }}
                    error={item.content ? "" : "ERROR"}
                    // isOptionEqualToValue={(option, value) => option.key === value.key}
                    value={item.content}

                    ignoreInput={true}
                    disableCloseOnSelect={false}
                />
            </TableCell>
            <TableCell padding="none" sx={{px: 2, height: theme.customTokens.defaultRowHeight}}>
                <CustomAutocompleteSelectField
                    multiple={false}
                    showHelperText={false}
                    onChange={(newValue) => handleChangeSettings(item.id, "config", newValue)}
                    options={configOptions}
                    error={validConfigOption ? "" : "ERROR"}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    value={validConfigOption ? item.config : null}
                />
            </TableCell>
            <TableCell padding="none" sx={{px: 2, height: theme.customTokens.defaultRowHeight}}>
                <CustomDateTimePicker
                    value={item.sendTime}
                    // label={" "}
                    onChange={(newValue) => handleChangeSettings(item.id, "sendTime", newValue)}
                />
            </TableCell>
        </TableRow>
    )
};

export default ManualScheduleTableRow;