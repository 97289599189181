import React, {useEffect} from 'react';
import {Box, IconButton, Tooltip, useTheme} from "@mui/material";
import useResource from "../hooks/useResource";
import useModal from "../hooks/useModal";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Link, SmartToy, Visibility} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {campaignService} from "../api/services/campaignService";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes, MAROPOST_ID} from "../utils/constants";
import Status from "../components/Status";
import ExternalLinkButton from "../components/Campaign/ExternalLinkButton";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import {mailingListService} from "../api/services/mailingListService";
import {verticalService} from "../api/services/verticalService";
import {brandService} from "../api/services/brandService";
import {partnerService} from "../api/services/partnerService";
import ContentReportingPreviewModal from "../components/ContentReporting/ContentReportingPreviewModal";
import RetryCampaignButton from "../components/Campaign/RetryCampaignButton";
import UnscheduleCampaignButton from "../components/Campaign/UnscheduleCampaignButton";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import {contentService} from "../api/services/contentService";
import useUnscheduleMultipleData from "../hooks/useUnscheduleMultipleItems";
import useRetryMultipleData from "../hooks/useRetryMultipleData";
import dayjs from "dayjs";
import {mailingListGroupService} from "../api/services/mailingListGroupService";
import {offerService} from "../api/services/offerService";
import {categoryService} from "../api/services/categoryService";
import {espIntegrationService} from "../api/services/espIntegrationService";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import CampaignPreviewModal from "../components/Campaign/CampaignPreviewModal";

const Campaigns = () => {
    const theme = useTheme()
    const urlParams = useUrlParams()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        campaignService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            send_datetime_start: {
                type: filtersElementsTypes.DATE,
                label: "Send Date Start",
                columns: 6,
                defaultValue: dayjs().startOf('month')
                // disableFuture: true
            },
            send_datetime_end: {
                type: filtersElementsTypes.DATE,
                label: "Send Date End",
                columns: 6,
                // disableFuture: true
            },
            status: {
                type: filtersElementsTypes.SELECT,
                options: [
                    {key: "Pending Delete", value: -3},
                    {key: "Pending", value: -2},
                    {key: "Processing", value: -1},
                    {key: "Successful", value: 0},
                    {key: "Error", value: 1},
                    {key: "Deleted", value: 2}

                ],
                label: "Status"
            },
            creation_method: {
                type: filtersElementsTypes.SELECT,
                options: [
                    {key: "Manual", value: 1},
                    {key: "Automatic", value: 2}
                ],
                defaultValue: {key: "Manual", value: 1},
                label: "Created By"
            },
            mailing_list_group_id: {
                label: "List Groups",
                apiCall: mailingListGroupService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            mailing_list_id: {
                label: "Lists",
                apiCall: mailingListService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            offer_id: {
                label: "Offers",
                apiCall: offerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            vertical_id: {
                label: "Verticals",
                apiCall: verticalService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            category_id: {
                label: "Categories",
                apiCall: categoryService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            brand_id: {
                label: "From Email (Brands)",
                apiCall: brandService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
                searchField: "from_email",
                setOptionLabel: (item) => item.from_email,
            },
            partner_id: {
                label: "Partners",
                apiCall: partnerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            esp_integration_id: {
                label: "ESP Integrations",
                apiCall: espIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            network_integration_id: {
                label: "Network Integrations",
                apiCall: networkIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            send_time_start: {
                type: filtersElementsTypes.TIME,
                label: "Send Time Start",
                columns: 6
            },
            send_time_end: {
                type: filtersElementsTypes.TIME,
                label: "Send Time End",
                columns: 6
            }
        },
        {},
        {},
        urlParams.queryParams
    )

    const {
        modalOpen,
        handleModalOpen,
        handleModalClose
    } = useModal()

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()

    const {
        unscheduleItems,
        handleOpenUnscheduleMultipleItemsModal,
        handleCloseUnscheduleMultipleItemsModal,
        handleUnscheduleMultipleItems,
        unscheduleItemsApi,
    } = useUnscheduleMultipleData(
        campaignService.unscheduleItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        retryItems,
        handleOpenRetryMultipleItemsModal,
        handleCloseRetryMultipleItemsModal,
        handleRetryMultipleItems,
        retryItemsApi,
    } = useRetryMultipleData(
        campaignService.retryItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'config.mailing_list.name',
            numeric: false,
            // disablePadding: true,
            label: 'List Name',
            nested: true,
            width: "370px",
        },
        {
            id: 'config.name',
            numeric: false,
            disablePadding: false,
            label: 'Config',
            width: "120px",
            nested: true
        },
        {
            id: 'send_time',
            align: "right",
            disablePadding: false,
            label: 'Send Time',
            fullDatetime: true,
            width: "140px",
        },
        {
            width: "40px",
            element: (item, combined) => {
                if (item.creation_method === 2)
                    return <Tooltip title={"Campaign Created Automatically"} arrow>
                        <SmartToy color={"disabled"}/>
                    </Tooltip>
                else
                    return null
            }
        },
        {
            id: 'id',
            disablePadding: false,
            label: 'Tracking Id',
            width: "120px",
        },
        {
            id: 'external_id',
            align: "right",
            disablePadding: false,
            label: 'ESP Id',
            width: "120px",
        },
        {
            id: 'content.offer.name',
            numeric: false,
            disablePadding: false,
            label: 'Offer',
            width: "250px",
            nested: true
        },
        {
            id: 'content.name',
            numeric: false,
            disablePadding: false,
            label: 'Content',
            width: "120px",
            nested: true
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            combined: "status_message",
            width: "60px",
            label: "Status",
            element: (item, combined) => {
                // Define color based on status value
                let statusColor;
                switch(item.status) {
                    case -3: // Pending Delete
                        statusColor = theme.palette.warning.dark;
                        break;
                    case -2: // Pending
                        statusColor = theme.palette.warning.light;
                        break;
                    case -1: // Processing
                        statusColor = theme.palette.info.main;
                        break;
                    case 0: // Successful
                        statusColor = theme.palette.success.main;
                        break;
                    case 1: // Error
                        statusColor = theme.palette.error.main;
                        break;
                    case 2: // Deleted
                        statusColor = theme.palette.grey[500];
                        break;
                    default:
                        statusColor = theme.palette.text.secondary;
                }

                return (
                    <Tooltip title={combined} arrow>
                        <Status size={24} color={statusColor} />
                    </Tooltip>
                );
            }
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'campaign_id',
            numeric: false,
            disablePadding: true,
            width: "48px",
            element: (item) => (
                <IconButton aria-label="preview" onClick={() => handleModalOpen(item.id)}>
                    <Visibility/>
                </IconButton>
            )
        },
        {
            element: (item) => <ExternalLinkButton campaign_data={item}/>,
            width: theme.customTokens.defaultTableIconWidth
        },
        {
            element: (item) => <RetryCampaignButton item={item} onSuccess={() => handleRefreshItems()}/>,
            width: theme.customTokens.defaultTableIconWidth
        },
        {
            element: (item) => <UnscheduleCampaignButton item={item} onSuccess={() => handleRefreshItems()}/>,
            width: theme.customTokens.defaultTableIconWidth
        },
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)

    return <>
        <Box>
            <CustomAppBar
                selectedItems={selectedItems}
                onDeleteMultipleItems={handleOpenUnscheduleMultipleItemsModal}
                onRetrySelectedItems={handleOpenRetryMultipleItemsModal}
            />
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                // disableSelecting={true}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        <CampaignPreviewModal
            open={modalOpen}
            handleClose={handleModalClose}
        />
        {
            (unscheduleItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Unschedule Campaigns"}
                description={`Are you sure you want to unschedule ${Object.keys(selectedItems).length} campaign(s)?`}
                handleClose={handleCloseUnscheduleMultipleItemsModal}
                handleSubmit={handleUnscheduleMultipleItems}
                loading={unscheduleItemsApi.loading}
                open={true}
            />
        }
        {
            (retryItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Retry Campaigns"}
                description={`Are you sure you want to retry ${Object.keys(selectedItems).length} campaign(s)?`}
                handleClose={handleCloseRetryMultipleItemsModal}
                handleSubmit={handleRetryMultipleItems}
                loading={retryItemsApi.loading}
                open={true}
            />
        }
    </>
}

export default Campaigns